import React from "react";
import PageWrapper from "../components/PageWrapper";
import Highlight from "react-highlight.js";
import "../addedcss/highlightjs.css";
import "../addedcss/styles.scss";
import Seo from "../components/Seo.js";
import dendrogram from "../assets/extra/Products/categories_dendrogram.svg";

const apidoc = () => {
  return (
    <>
      <Seo
        title="API Documentation | Genify"
        description="Documentation of Genify's machine-learning models API"
      />

      <PageWrapper
        headerConfig={{
          theme: "light",
          align: "right",
          variant: "primary",
          isFluid: true,
          source: "genify",
          button: "null", // cta, account, null
        }}
        footerConfig={{
          style: "style1", //style1, style2
        }}
      >
        <div className="apidoc mb-35">
          <h2>DOCUMENTATION: GENIFY MACHINE-LEARNING MODELS API </h2>

          <br />
          <h3>Introduction</h3>
          <p>
            Welcome onboard! Our REST API enables your company to
            programmatically use our machine-learning powered model.
          </p>

          <br />
          <h3>Authorization</h3>
          <p>
            A token is required to use the API. In the examples below, we will
            use this demo token:
          </p>
          <p style={{ color: "#df322f" }}>
            uooqTdoyXHV9iC8hor7WtcQQPbHFRSc1fI8ifiwcj7
          </p>
          <p>Please Talk to us in order to obtain a token.</p>

          <br />
          <h3>Getting Started</h3>
          <p>
            The examples below show how to classify a banking transaction using
            its description and amount:
          </p>

          <br />
          <p>
            <em>Python</em>
          </p>
          <Highlight language={"python"}>
            {
              'import requests \n\n# A user is granted a token, such as: \ntoken = "uooqTdoyXHV9iC8hor7WtcQQPbHFRSc1fI8ifiwcj7"\n\n# This token is used to determine the ML models a user has access to\nheaders = {\'Authorization\': \'Bearer \' + token} \n\nurl = "http://www.genify.ai/api/v1.0/classifier?description=starbucks&amount=45" \nresponse = requests.get (url, headers=headers) \nprint (response.text) \n\n# Example of a forbidden access (HTTP error 403) \nurl = "http://www.genify.ai/api/v1.0/recosys" \nresponse = requests.get (url, headers=headers) \nprint (response)'
            }
          </Highlight>

          <br />
          <br />
          <h3>
            Selecting the machine-learning model to run using the model ID
          </h3>
          <p>
            Any of our machine learning models can be run via our API using a
            model id instead of the model name. Below is an example (using the
            same token and headers):
          </p>

          <br />
          <p>
            <em>Python</em>
          </p>
          <Highlight language={"python"}>
            {
              'url = "http://www.genify.ai/api/v1.0/generic?model_id=1&description=starbucks&amount=45" \nresponse = requests.get (url, headers=headers) \nprint (response.text) \n\nurl = "http://www.genify.ai/api/v1.0/generic?model_id=2" \nresponse = requests.get (url, headers=headers) \nprint (response) \n\nurl = "http://www.genify.ai/api/v1.0/classifier/?description=starbucks&amount=31" \nresponse = requests.get(url, headers=headers) \nprint(response.json())'
            }
          </Highlight>

          <br />
          <p>Optionaly, a date and time can be provided:</p>
          <br />
          <p>
            <em>Python</em>
          </p>
          <Highlight language={"python"}>
            {
              'import requests \nurl = "http://www.genify.ai/api/v1.0/classifier/?description=starbucks&amount=31&date=12Jun201903:45pm" \nresponse = requests.get(url, headers=headers) \nprint(response.json())'
            }
          </Highlight>

          <br />
          <br />
          <h3>Inputs of the transaction classifier API</h3>
          <p>The transaction classifier accepts the following parameters:</p>
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Input</th>
                <th>Description</th>
                <th>Mandatory</th>
                <th>Type/format</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <pre>Description</pre>
                </td>
                <td>
                  Nomenclature associated with the transaction (e.g. 'Starbucks
                  Brooklyn')
                </td>
                <td>Yes</td>
                <td>String</td>
              </tr>
              <tr>
                <td>
                  <pre>Amount</pre>
                </td>
                <td>Value of the transaction in the relevant currency</td>
                <td>Yes</td>
                <td>Decimal</td>
              </tr>
              <tr>
                <td>
                  <pre>DateTime</pre>
                </td>
                <td>Date and time of occurence of transaction</td>
                <td>No</td>
                <td>"DDMonYYYYHH:MMam/pm"</td>
              </tr>
            </tbody>
          </table>

          <br />
          <br />
          <h3>Responses</h3>
          <br />
          <p>
            Following the steps listed above, the expected JSON response should
            be:
          </p>
          <Highlight language={"javascript"}>
            {
              '{\n   "category_description": "Coffeeshop",\n   "category_id": 1003\n}'
            }
          </Highlight>

          <br />
          <p>
            where <pre>category_description</pre> is the label assigned to the
            transaction by Genify's model and <pre>category_id</pre> the id of
            the label in the below category hierarchy.
          </p>
          <br />
          <p>
            Genify's hierarchy of transaction categories can be found in the
            below dendrogram.
          </p>

          <br />
          <br />
          <h3>Dendrogram of Transactions Categories</h3>
          <p>
            The child categories are displayed on the outer ring, and the parent
            categories on the inner ring. The API currently only returns child
            categories.
          </p>
          <img
            src={dendrogram}
            alt="dendrogram image"
            style={{ margin: "0 auto 0 auto", width: "75%" }}
          />

          <br />
          <br />
          <h3>Errors</h3>
          <p>
            In case, for example, the description or amount isn't provided in
            the transaction classifier API call, the API returns an error
            message with HTTP status code 400:
          </p>
          <Highlight language={"python"}>
            {
              'url = "http://www.genify.ai/api/v1.0/classifier/?description=starbucks"\nresponse = requests.get(url, headers=headers)\nprint(response.status_code) # prints 400'
            }
          </Highlight>
        </div>
      </PageWrapper>
    </>
  );
};
export default apidoc;
